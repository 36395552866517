/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import styled from "@emotion/styled";
import { Zap } from "@emotion-icons/octicons";
import { Html5, ReactLogo, Sass, Php } from "@emotion-icons/fa-brands";
import {
    Gatsby,
    Microsoftsqlserver,
    Javascript,
    Css3,
    Python,
    Java,
    Assemblyscript,
    Csharp,
    Rstudio,
    Arduino,
    Jquery,
    Microsoftvisio
} from "@emotion-icons/simple-icons";
import { EmotionIconBase } from "@emotion-icons/emotion-icon";
import List from "./lists";
import Icon from "./icon";

const IconStyleManager = styled.div`
    ${EmotionIconBase} {
        width: 100%;
        height: 100%;
    }
`;

const IconGrid = ({ iconSize, small }) => {
    const skills = [
        {
            title: "React",
            color: "#61DBFB",
            background: "#f2fcff",
            highlight: <ReactLogo size={iconSize} />
        },
        {
            title: "Gatsby",
            color: "#663399",
            background: "#faf5ff",
            highlight: <Gatsby size={iconSize} />
        },
        {
            title: "SQL",
            color: "#00758F",
            background: "#f5fdff",
            highlight: <Microsoftsqlserver size={iconSize} />
        },
        {
            title: "HTML5",
            color: "#e34c26",
            background: "#fff7f5",
            highlight: <Html5 size={iconSize} />
        },
        {
            title: "JavaScript",
            color: "#f0db4f",
            background: "#fffdf2",
            highlight: <Javascript size={iconSize} />
        },
        {
            title: "Css3",
            color: "#264de4",
            background: "#f2f5ff",
            highlight: <Css3 size={iconSize} />
        },
        {
            title: "SASS",
            color: "#cc6699",
            background: "#fff2f9",
            highlight: <Sass size={iconSize} />
        },
        {
            title: "Java",
            color: "#f89820",
            background: "#fff8f0",
            highlight: <Java size={iconSize} />
        },
        {
            title: "Python",
            color: "#3776ab",
            background: "#f2f9ff",
            highlight: <Python size={iconSize} />
        },
        {
            title: "PHP",
            color: "#474A8A",
            background: "#f2f3ff",
            highlight: <Php size={iconSize} />
        },
        {
            title: "Arduino",
            color: "#038C8C",
            background: "#f2ffff",
            highlight: <Arduino size={iconSize} />
        },

        {
            title: "MIPS Assembly",
            color: "#017BBC",
            background: "#f2fbff",
            highlight: <Assemblyscript size={iconSize} />
        },
        {
            title: "C#",
            color: "#B064AB",
            background: "#fff2fe",
            highlight: <Csharp size={iconSize} />
        },
        {
            title: "jQuery",
            color: "#0868AC",
            background: "#f2faff",
            highlight: <Jquery size={iconSize} />
        },
        {
            title: "R",
            color: "#f89820",
            background: "#fff8f0",
            highlight: <Rstudio size={iconSize} />
        },
        {
            title: "VBA",
            color: "#A70050",
            background: "#fff2f8",
            highlight: <Microsoftvisio size={iconSize} />
        }
    ];

    const skillsSummary = skills.slice(0, 8);

    return (
        <>
            {small ? (
                <>
                    <div
                        className="project-grid"
                        sx={{
                            display: ["none", "none", "grid", "grid"],
                            gridTemplateColumns: [
                                "1fr",
                                "repeat(2, 1fr)",
                                "repeat(3, 1fr)",
                                "repeat(4, 1fr)"
                            ],
                            gridTemplateRows: [
                                "1fr",
                                "repeat(3, 1fr)",
                                "repeat(3, 1fr)",
                                "repeat(2, 1fr)"
                            ],
                            gridAutoRows: 0,
                            overflowY: "hidden",
                            pt: [5, 5, 6]
                        }}
                    >
                        {skills.map(item => (
                            <IconStyleManager>
                                <Icon
                                    icon={item.highlight}
                                    title={item.title}
                                    color={item.color}
                                    background={item.background}
                                />
                            </IconStyleManager>
                        ))}
                    </div>
                    <div
                        className="project-grid"
                        sx={{
                            display: ["block", "block", "none"],
                            gridTemplateColumns: [
                                "1fr",
                                "repeat(2, 1fr)",
                                "repeat(3, 1fr)",
                                "repeat(4, 1fr)"
                            ],
                            gridGap: 2,
                            pt: [5, 5, 6]
                        }}
                    >
                        <List list={skillsSummary} />
                    </div>
                </>
            ) : (
                <>
                    <div
                        className="project-grid"
                        sx={{
                            display: ["none", "none", "grid", "grid"],
                            gridTemplateColumns: [
                                "1fr",
                                "repeat(2, 1fr)",
                                "repeat(3, 1fr)",
                                "repeat(4, 1fr)"
                            ],
                            pt: [5, 5, 6]
                        }}
                    >
                        {skills.map(item => (
                            <IconStyleManager>
                                <Icon
                                    icon={item.highlight}
                                    title={item.title}
                                    color={item.color}
                                    background={item.background}
                                />
                            </IconStyleManager>
                        ))}
                    </div>
                    <div
                        className="project-grid"
                        sx={{
                            display: ["block", "block", "none"],
                            gridTemplateColumns: [
                                "1fr",
                                "repeat(2, 1fr)",
                                "repeat(3, 1fr)",
                                "repeat(4, 1fr)"
                            ],
                            gridGap: 2,
                            pt: [5, 5, 6]
                        }}
                    >
                        <List list={skills} />
                    </div>
                </>
            )}
        </>
    );
};

export default IconGrid;
