/** @jsx jsx */
import { jsx } from "theme-ui";

const Icon = ({ icon, title, color, background }) => {
    return (
        <div
            className="icon-container"
            cursor-scale="scale-up"
            cursor-type="color-change"
            icon-name={title}
            icon-color={`${color}b3`}
            sx={{
                color: color,
                bg: background,
                p: 2,
                m: 1
            }}
        >
            {icon}
        </div>
    );
};

export default Icon;
